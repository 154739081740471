
.square {
  position: relative;
  /* max-width: 650px; */
  margin: auto;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.square-content {
  position: absolute;
  width: 100%;
  height: 100%;
}

.crossword-game {
  display: flex;
  margin: 20px;
  max-height: 650px;
  height: 100vh;
  width: 100%;
  max-width: 1300px;
  margin: auto;
}

.hg-theme-default.hg-theme-highlight .hg-button.hg-activeButton {
  background-color: blue;
  color: white;
}

.sudoku-inner-border {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.sudoku-left-border {
  border-left: 4px solid black;
}

.sudoku-top-border {
  border-top: 4px solid black;
}
